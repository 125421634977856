import * as React from "react"
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import Landing from "../components/landing/landing";
import Projects from "../components/projects/projects";
import ContactForm from "../components/contact-form/contact-form";
import About from "../components/about/about";
import Houses from "../components/houses/houses";
import {graphql} from "gatsby";


const HomePage = ({data}) => {

  return (
    <Layout contactInfo={data.api.contactInfo}>
      <Seo />

      <Landing images={data?.api?.about?.landingImages ?? []} />

      <About about={data.api.about} />

      <Houses flats={data.api.flats} />

      <ContactForm contactInfo={data.api.contactInfo} />

      <Projects projects={data.api.projects} />
    </Layout>
  )
}

export const query = graphql`
    query {
        api {
            about:aboutSingleton {
                shortText
                text
                aboutImage {
                    path
                    file {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
                landingImages {
                    path
                    file {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            flats:flatsCollection(sort: {_o: 1}) {
                id
                floor
                dispositions
                area
                terrace
                garden
                garage
                price
                state
            }
            projects:projectsCollection(sort: {_o: 1}) {
                title
                url
                image {
                    path
                    file {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            contactInfo:contactSingleton {
                email
                phone
                name
            }
        }
    }
`;

export default HomePage;
