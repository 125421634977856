import {useEffect, useState} from "react";

const getWindowSize = () => (
  typeof window !== "undefined" ? {
    width: window?.innerWidth,
    height: window?.innerHeight
  } : {
    width: 0,
    height: 0
  }
);

export const useWindowSize = () => {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    const onResize = () => {
      setSize(getWindowSize());
    };

    if (typeof window !== "undefined") {
      window?.addEventListener('resize', onResize.bind(this));
    }

    return () => {
      if (typeof window !== "undefined") {
        window?.removeEventListener('resize', onResize.bind(this));
      }
    };
  }, []);

  return size;
};
