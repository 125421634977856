import React from "react";
import * as styles from "./about.module.scss";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const About = ({about}) => {
  return (
    <section data-navigation-id="o-projektu" style={{position: "relative"}}>
      <div className={styles.container}>
        <div className={styles.text}>
          <h2>o projektu</h2>
          <p>{about?.shortText}</p>
          <Link to={"/o-projektu"}>
            <button>Více <i className="fas fa-long-arrow-right" /></button>
          </Link>
        </div>
        <div className={styles.line} />
        <div className={styles.relative}>
          <div className={styles.imageWrapper}>
            <GatsbyImage alt={"Ilustrace"}
                         image={getImage(about?.aboutImage?.file)}
                         objectFit={"contain"}
                         className={styles.image}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;
