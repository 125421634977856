import * as React from "react";
import Header from "../header/header";
import * as styles from "./layout.module.scss";
import Footer from "../footer/footer";

const Layout = ({children, noFooter, contactInfo}) => {
  return (
    <>
      <Header />

      <main className={styles.main}>
        {children}
      </main>

      {!noFooter && <Footer contactInfo={contactInfo} />}
    </>
  );
};

export default Layout;
