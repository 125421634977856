// extracted by mini-css-extract-plugin
export var container = "houses-module--container--DCce1";
export var flatsCanvas = "houses-module--flats-canvas--1NGBI";
export var floorTitle = "houses-module--floor-title--1rxXl";
export var groundplan = "houses-module--groundplan--33Pmp";
export var groundplanImage = "houses-module--groundplan-image--3bgJm";
export var groundplanWrapper = "houses-module--groundplan-wrapper--3GjVa";
export var headline = "houses-module--headline--l6OB4";
export var line = "houses-module--line--3XXI4";
export var next = "houses-module--next--3J5aG";
export var prev = "houses-module--prev--1XvuB";
export var switchButton = "houses-module--switchButton--a7x7D";
export var tableWrapper = "houses-module--table-wrapper--2Oadh";
export var visible = "houses-module--visible--2RaLe";