// takes a string that contains coords eg - "227,307,261,309, 339,354, 328,371, 240,331"
// draws a line from each co-ord pair to the next - assumes starting point needs to be repeated as ending point.
import {useCallback} from "react";

const paintPoly = (ctx, coordStr) => {
  const mCoords = coordStr.split(',');
  let i, n;
  n = mCoords.length;

  ctx.globalAlpha = 0.8;

  ctx.beginPath();
  ctx.moveTo(mCoords[0], mCoords[1]);
  for (i=2; i<n+1; i+=2)
  {
    ctx.lineTo(mCoords[i], mCoords[i+1]);
  }
  ctx.lineTo(mCoords[0], mCoords[1]);
  ctx.fill();

  ctx.globalAlpha = 1;
};

const paintText = (ctx, text, coordStr, pointGiven) => {
  ctx.fillStyle = '#ffffff';
  const mCoords = coordStr.split(',');

  if (pointGiven) {
    ctx.fillText(text, +mCoords[0] - 45, mCoords[1]);
  } else {
    let top, left, bot, right;
    left = mCoords[0];
    top = mCoords[1];
    right = mCoords[2];
    bot = mCoords[3];
    ctx.fillText(text, Math.abs(((+right + +left) / 2) - 45), (+top + +bot) / 2);
  }


  ctx.fillStyle = '#367ca4';
};

const paintRect = (ctx, coordStr) => {
  const mCoords = coordStr.split(',');
  let top, left, bot, right;
  left = mCoords[0];
  top = mCoords[1];
  right = mCoords[2];
  bot = mCoords[3];
  ctx.globalAlpha = 0.8;
  ctx.fillRect(left,top,right-left,bot-top);
  ctx.globalAlpha = 1;
};

const noopPaint = () => {
  console.log('Context not available, skipping paint.');
};

export const useCanvasPainter = () => {
  const painter = useCallback(
    ctx =>
      ctx instanceof CanvasRenderingContext2D ? {
        paintRect: paintRect.bind(this, ctx),
        paintPoly: paintPoly.bind(this, ctx),
        paintText: paintText.bind(this, ctx),
      } : {
        paintRect: noopPaint.bind(this),
        paintPoly: noopPaint.bind(this),
        paintText: noopPaint.bind(this),
      },
    []
  );

  return painter;
};
