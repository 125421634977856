import * as React from "react"

import * as styles from "./landing.module.scss";
import {Link} from "gatsby";
import LandingSlideshow from "./landing-slideshow/landing-slideshow";

const Landing = ({images}) => {
  return (
    <section className={styles.landing} data-navigation-id="domu">
      <LandingSlideshow images={images} />

      <div className={styles.text}>
        <div className={styles.headlineWrapper}>
          <div className={styles.line} />
          <div className={styles.headline}>
            <h2>Rezidence</h2>
            <h1>Sobín</h1>
          </div>
          <div className={styles.line} />
        </div>
        <Link to="/#o-projektu">
          <i className="fal fa-long-arrow-down" />
        </Link>
      </div>
    </section>
  );
};

export default Landing;
