import React from "react";
import * as styles from "./project.module.scss";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Project = ({name, url, image}) => {
  return (
    <div className={styles.container}>
      <a href={url} target="_blank" rel="noreferrer" className={styles.content}>
        <GatsbyImage image={getImage(image.file)}
                     alt="Obrázek projektu"
                     objectFit={"cover"}
                     objectPosition={"center"}
                     className={styles.imageWrapper}
                     imgClassName={styles.image}
        />
        <div className={styles.decor}>
          <div className={styles.bottomLeft}>
            <div className={styles.square} />
            <div className={styles.horizontal} />
            <div className={styles.vertical} />
          </div>
          <div className={styles.topRight}>
            <div className={styles.square} />
            <div className={styles.horizontal} />
            <div className={styles.vertical} />
          </div>
        </div>
        <div className={styles.overlay}>
          <div className={styles.color} />
          <h4>{name}</h4>
        </div>
      </a>
    </div>
  )
}

export default Project;
