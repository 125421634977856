import * as React from "react";
import * as styles from "./footer.module.scss";
import {StaticImage} from "gatsby-plugin-image";

const Footer = ({contactInfo}) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <StaticImage src="../../images/logo-white.png"
                     alt="logo"
                     className={styles.logoWrapper}
                     objectFit={"contain"}
        />
        <div className={styles.contact}>
          <h4>Kontakt</h4>
          <a href={`mailto:${contactInfo?.email}`}>{contactInfo?.email}</a>
          <a href={`tel:${contactInfo?.phone}`}>{contactInfo?.phone}</a>
        </div>
      </div>
      <div className={styles.copyright}>
        <small>Copyright &copy; {currentYear} VP Developers</small>
        <small>Created by <a href="https://ledl.dev" target="_blank" rel="noreferrer">Martin Lédl</a></small>
      </div>
    </footer>
  );
};

export default Footer;
