import React from "react";
import * as styles from "./projects.module.scss";
import Project from "./project/project";

const Projects = ({projects}) => {
  return (
    <section className={styles.container} data-navigation-id="projekty">
      <div className={styles.headline}>
        <h2>Další projekty</h2>
        <div className={styles.underline}>
          <div className={styles.line} />
          <div className={styles.circle} />
          <div className={styles.line} />
        </div>
      </div>

      <div className={styles.projects}>
        {projects.map((project, i) => (
          <Project name={project.title}
                   key={`${i}-${project.title}`}
                   url={project.url}
                   image={project.image}
          />
        ))}
      </div>
    </section>
  )
}

export default Projects;
