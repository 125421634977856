// extracted by mini-css-extract-plugin
export var bottomLeft = "project-module--bottom-left--2rsTO";
export var color = "project-module--color--2bfJk";
export var container = "project-module--container--2P9m3";
export var content = "project-module--content--NUbEx";
export var decor = "project-module--decor--LD6eY";
export var horizontal = "project-module--horizontal--2-AI-";
export var image = "project-module--image--e3z1t";
export var imageWrapper = "project-module--image-wrapper--1E0P8";
export var overlay = "project-module--overlay--3zflU";
export var square = "project-module--square--Ca-OE";
export var topRight = "project-module--top-right--3YxgR";
export var vertical = "project-module--vertical--2YvDc";