import React, {useCallback, useEffect, useRef, useState} from "react";
import * as styles from "./houses.module.scss";
import {Link} from "gatsby";
import classNames from "classnames";
import houses from "/static/images/houses.jpg";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useCanvasPainter} from "../../hooks/useCanvasPainter";

const Houses = ({flats}) => {
  const windowSize = useWindowSize();

  // Problem with canvas incorrect height was solved by triggering the useEffect callback
  // in onLoad function on the groundplan image. That is why here it is true and on load
  // changed to false
  const [tableVisible, setTableVisible] = useState(true);

  const [noGroundplan, setNoGroundplan] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [currentFloorNumber, setCurrentFloorNumber] = useState(1);
  const [factor, setFactor] = useState(1);
  const gpImages = [houses];
  const maxFloor = 1;
  const gpWidth = 2260;
  const gpRef = useRef();
  const flatsOnGP = [
    {
      id: "1",
      textCenter: "500,300",
      coords: "495,491,954,491,952,303,916,301,916,196,829,194,829,150,581,150,579,182,493,186"
    },
    // {
    //   id: "2",
    //   textCenter: "1000,300",
    //   coords: "1076,535,1533,535,1531,345,1497,345,1497,239,1408,241,1408,208,1170,208,1170,229,1074,229"
    // },
    // {
    //   id: "3",
    //   textCenter: "1500,200",
    //   coords: "1703,549,1703,257,1763,253,1763,208,2068,212,2068,251,2136,253,2138,487,2168,487,2168,551,2138,549,2140,621,2002,621,2004,549"
    // },
  ];

  // Canvas stuff
  const hdc = useRef(null);
  const canvasRef = useRef();
  const painter = useCanvasPainter();

  function myHover(element) {
    // const hoveredElement = element;
    const coordStr = element.target.getAttribute('coords');
    const areaType = element.target.getAttribute('shape');

    switch (areaType) {
      case 'polygon':
      case 'poly':
        painter(hdc.current).paintPoly(coordStr);
        break;

      case 'rect':
        painter(hdc.current).paintRect(coordStr);
        break;

      default:
        painter(hdc.current).paintPoly(coordStr);
        break;
    }
  }

  function myLeave() {
    const canvas = canvasRef;
    hdc.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
  }

  const myInit = useCallback(() => {
    if (
      !(canvasRef.current instanceof HTMLCanvasElement) ||
      !(gpRef.current instanceof HTMLImageElement)
    ) {
      return;
    }

    // get it's position and width+height
    const w = gpRef.current.clientWidth;
    const h = gpRef.current.clientHeight;

    // make same size as the image
    canvasRef.current.setAttribute('width', w + 'px');
    canvasRef.current.setAttribute('height', h + 100 + 'px');

    hdc.current = canvasRef.current.getContext('2d');

    // set the 'default' values for the colour/width of fill/stroke operations
    hdc.current.fillStyle = '#367ca4';
    hdc.current.strokeStyle = '#367ca4';
    hdc.current.lineWidth = 2;
    hdc.current.font = '18px Roboto';
  }, [firstRender]);

  const generatePoints = useCallback((pointString) => {
    return pointString.split(",").map(dimension => {
      return dimension * factor
    }).join(",")
  }, [factor]);

  useEffect(() => {
    myInit();
    const realWidth = gpRef.current.clientWidth;
    setFactor(realWidth / gpWidth);

    if (noGroundplan === false) {
      if (windowSize.width <= 625) {
        setNoGroundplan(true);
      }
    }

    if (noGroundplan === true) {
      if (windowSize.width > 625) {
        setNoGroundplan(false);
      }
    }
  }, [gpRef, windowSize, noGroundplan, tableVisible]);

  function getFlatStateString(state) {
    return (state === "available" ? "K dispozici" : state === "reserved" ? "Rezervováno" : "Prodáno");
  }

  return (
    <section className={styles.container} data-navigation-id="byty">
      <div className={styles.headline}>
        <h2>Nabídka bytů</h2>
        <div className={styles.line}/>
        <div className={styles.line}/>
      </div>
      {noGroundplan === false && (
        <div className={styles.switchButton}>
          <button
            onClick={() => setTableVisible(prev => !prev)}
          >
            Zobrazit {tableVisible ? "půdorys" : "tabulku"}
          </button>
        </div>
      )}

      <div className={classNames({
        [styles.groundplanWrapper]: true,
        [styles.visible]: !tableVisible && (noGroundplan === false)
      })}>
        <button className={styles.prev} onClick={() => {
          if (currentFloorNumber > 1) {
            setCurrentFloorNumber(prevState => prevState - 1)
          }
        }} disabled={currentFloorNumber <= 1}>
          <i className="fas fa-arrow-left"/>
          {/*<p>předchozí</p>*/}
        </button>
        <div className={styles.groundplan}>
          <img src={gpImages[currentFloorNumber - 1]}
               alt="půdorys"
               className={styles.groundplanImage}
               useMap="#flat-map"
               ref={gpRef}
               onLoad={() => setTableVisible(false)}
          />

          <map name="flat-map" id="flat-map">
            {flatsOnGP.map(flat => (
              <area alt={flat.id} title={`Zobrazit dům ${flat.id}`} href={`/domy/${flat.id}`}
                    target={"_blank"}
                    coords={generatePoints(flat.coords)}
                    shape={flat.coords.split(",").length === 4 ? "rect" : "poly"}
                    data-text-center={generatePoints(flat.textCenter)}
                    onMouseOver={myHover}
                    onMouseOut={myLeave}
                    key={`area-${flat.id}`}
              />
            ))}
          </map>

          <canvas id="flatsCanvas" className={styles.flatsCanvas} ref={canvasRef}/>
        </div>
        <button className={styles.next} onClick={() => {
          if (currentFloorNumber < maxFloor) {
            setCurrentFloorNumber(prevState => prevState + 1)
          }
        }} disabled={currentFloorNumber >= maxFloor}>
          {/*<p>další</p>*/}
          <i className="fas fa-arrow-right"/>
        </button>
      </div>


      <div className={classNames({
        [styles.tableWrapper]: true,
        [styles.visible]: tableVisible || noGroundplan
      })}>
        <table>
          <tbody>
          <tr>
            <th>Číslo</th>
            <th>Podlaží</th>
            <th>Dispozice</th>
            <th>Užitná plocha</th>
            <th>Terasa</th>
            <th>Zahrada</th>
            <th>Garážové stání</th>
            <th>Nezávazná rezervace</th>
            <th>Ceny od</th>
            <th>Stav</th>
            <th>Detail</th>
          </tr>
          {flats.map(flat => (
            <tr key={`tableCell-${flat.id}`}>
              {flat.id != null ? (
                <td>{flat.id}</td>
              ) : (
                <td>-</td>
              )}
              {flat.floor != null ? (
                <td>{flat.floor}</td>
              ) : (
                <td>-</td>
              )}
              {flat.dispositions != null ? (
                <td>{flat.dispositions}</td>
              ) : (
                <td>-</td>
              )}
              {flat.area != null ? (
                <td>{flat.area} m<sup>2</sup></td>
              ) : (
                <td>-</td>
              )}
              {flat.terrace != null ? (
                <td>{flat.terrace} m<sup>2</sup></td>
              ) : (
                <td>-</td>
              )}
              {flat.garden != null ? (
                <td>{flat.garden} m<sup>2</sup></td>
              ) : (
                <td>-</td>
              )}
              {flat.garage != null ? (
                <td>{flat.garage} m<sup>2</sup></td>
              ) : (
                <td>-</td>
              )}
              <td><Link to={`/byty/${flat.id}#kontakt`}>Rezervujte zde</Link></td>
              {flat.price != null ? (
                <td>{flat.price} Kč</td>
              ) : (
                <td>-</td>
              )}
              {flat.state != null ? (
                <td>{getFlatStateString(flat.state)}</td>
              ) : (
                <td>-</td>
              )}
              <td><Link to={`/byty/${flat.id}`}>Více</Link></td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default Houses;
