import * as React from 'react';
import { useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './landing-slideshow.module.scss';
import {Swiper, SwiperSlide} from "swiper/react";

const LandingSlideshow = ({ images }) => {
  const landingSlider = useRef(null);
  console.log(images);

  return (
    <div ref={landingSlider} className={styles.landingSliderContainer}>
      <Swiper
        slidesPerView={1}
        effect={"fade"}
        fadeEffect={{crossFade: true}}
        autoplay={{
          delay: 8000,
        }}
        speed={800}
        loop={true}
        pagination={{ clickable: true }}
        className={styles.landingSlider}
      >
        {images?.map((image, i) => {

          return (
            <SwiperSlide key={`landingSlide${i}`} className={styles.slide}>
              <GatsbyImage
                alt={image?.meta?.alt ?? 'Vizualizace'}
                image={getImage(image?.file)}
                className={styles.image}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

LandingSlideshow.defaultProps = {
  projects: [],
};

export default LandingSlideshow;
